<template>
    <div>
        <div
            class="bg-white p-6 rounded-lg shadow-lg grid grid-cols-3 gap-4 mb-6"
        >
            <div class="col-span-1">
                <img
                    :src="
                        this.baseUrl +
                        '/employee/avatar/' +
                        employee.employee._id
                    "
                    class="w-20 mx-auto rounded-full mb-4"
                    alt=""
                />
                <p class="text-lg text-center">
                    {{ employee.employee.first_name }}
                </p>
            </div>
            <div class="col-span-2 h-full text-center flex items-center" v-if="todayLoginState()">
                <div class="w-full">
                    <p class="text-6xl mb-2">{{ calcProgress() }}%</p>
                    <p class="" v-if="todayLoginState() === 1">{{ calcTimeLeft() }}</p>
                    <p v-if="todayLoginState() === 2">Good Night and Sleep Tight</p>
                </div>
            </div>
            <div v-else class="col-span-2 h-full text-center flex items-center">
                <div class="w-full">
                    <p class="text-6xl mb-2">0%</p>
                    <p class="text-gray-400">No login found for today.</p>
                </div>
            </div>
        </div>
        <p v-show="errorMsg" class="py-2 px-4 bg-red-500 text-white rounded-lg mb-6">{{errorMsg}}</p>
        <div class="bg-white p-6 rounded-lg shadow-lg mb-6" v-if="todayLoginState() === 1">
            <p class="text-xl font-bold ">Today</p>
            <div class="flex items-center">
                <p class="w-full">Logged in at {{getDate12()}}</p>
                <button class="btn bg-red-500 text-white disabled:bg-red-300" @click="scan = true">Logout</button>
            </div>
        </div>
        <div class="bg-white p-6 rounded-lg shadow-lg mb-6" v-if="todayLoginState() === 0">
            <p class="text-xl font-bold">Today</p>
            <div class="flex items-center">
                <p class="w-full">Please login</p>
                <button
                    :disabled="inSubmission"
                    class="btn bg-green-500 text-white disabled:bg-green-300"
                    @click="scan = true"
                >
                    Login
                </button>
            </div>
        </div>
        <div class="bg-white p-6 rounded-lg shadow-lg mb-6" v-if="todayLoginState() === 2">
            <p class="text-xl font-bold mb-4">Today</p>
            <div class="flex items-center">
                <p class="w-full text-center">Your shift has ended. Thank you</p>
            </div>
        </div>
        <div class="bg-white p-6 rounded-lg shadow-lg mb-6">
            <p class="text-xl font-bold mb-4">This Week</p>
            <div class="divide-y">
                <div v-for="w in weekData" :key="w.day" class="py-2 w-full grid grid-cols-2">
                    <div>
                        <p class="text-lg">{{w.day}}</p>
                        <p class="text-gray-400 text-xs">{{w.date}}</p>
                    </div>
                    <div class="text-right">
                        <p class="text-lg">{{w.duration}}</p>
                        <p class="text-xs text-gray-400">
                            <span class="">{{w.start}}</span> -
                            <span class="">{{w.end}}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <scan v-if="scan" :onClose="closeScan" :onScan="submitCode" />
    </div>
</template>

<script>
import axios from 'axios';
import Scan from "@/components/Scan.vue";
import dates from "@/utils/dates";

export default {
    components: { Scan },
    name: "Dashboard",
    props: ["baseUrl", "employee", "onUpdate"],
    data() {
        return {
            scan: false,
            weekData: [],
            inSubmission: false,
            errorMsg: null
        };
    },
    methods: {
        closeScan() {
            this.scan = false;
        },
        calcProgress() {
            const max = 8 * 60;
            const attendance = this.$props.employee.attendances[0];
            let date = new Date();
            if (attendance.end) {
                date = new Date(
                    dates.formatDate(new Date(attendance.date)) +
                        " " +
                        attendance.end
                );
            }
            const progress = dates.getTotalMinutes(date, attendance.start);
            const percentage = Math.round((progress / max) * 100);
            return percentage;
        },
        calcTimeLeft() {
            const time = dates.calculateShiftProgress(
                this.$props.employee.attendances[0].start
            );
            // console.log(time)
            let msg = time.hoursLeft + ' hours'
            if(time.hoursLeft === 0) {
                msg = time.minutesLeft + ' minutes'
            }
            return `You have ${msg} left in your shift today`;
        },
        getWeekData() {
            const attendances = this.$props.employee.attendances;
            const _dates = this.getCurrentWeekDates();
            const attsObject = {};
            for (let i in attendances) {
                attsObject[dates.formatDate(new Date(attendances[i].date))] =
                    attendances[i];
                attsObject[
                    dates.formatDate(new Date(attendances[i].date))
                ].date = new Date(attendances[i].date);
            }

            for(let j = _dates.length - 1; j >= 0; j--){
                const d = _dates[j]
                if (attsObject[d]) {
                    // console.log(attsObject[d])
                    const wObj = {};
                    wObj.day = dates.weekDays[attsObject[d].date.getDay()];
                    wObj.date = dates.getDateString(attsObject[d].date);
                    wObj.duration = attsObject[d].end ? dates.getTimeStringFromMS(
                        attsObject[d].duration
                    ) : dates.calculateDuration(attsObject[d].start);
                    wObj.start = dates.convertTimeTo12Hour(attsObject[d].start);
                   
                    wObj.end = attsObject[d].end ? dates.convertTimeTo12Hour(attsObject[d].end) : 'Open'
                    this.weekData.push(wObj);
                }
            }
        },
        todayLoginState(){
            if(!this.$props.employee.attendances[0]){
                return 0
            }
            if(dates.compareDates(new Date(), new Date(this.$props.employee.attendances[0].date)) === 0){
                if(this.$props.employee.attendances[0].end){
                    return 2
                }
                return 1
            }
            return 0
        },
        getCurrentWeekDates() {
            const currentDate = new Date();
            const currentDay = currentDate.getDay(); // Sunday = 0, Monday = 1, ...
            const sunday = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate() - currentDay
            );
            const _dates = [dates.formatDate(sunday)];

            for (let i = 1; i < 7; i++) {
                const date = new Date(sunday);
                date.setDate(sunday.getDate() + i);
                if (date <= currentDate) {
                    _dates.push(dates.formatDate(date));
                }
            }

            return _dates;
        },
        async submitCode(code){
            this.errorMsg = null
            if(this.inSubmission){
                return
            }
            this.inSubmission = true
            try{
                const token = localStorage.getItem("_token");
                if (!token) {
                    throw new Error("Token not found.");
                }
                const headers = {
                    Authorization: `Bearer ${token}`,
                };
                await axios.get(this.$props.baseUrl + '/hrm/attendance/qr/' + code, {headers})
                await this.$props.onUpdate()
                this.getCurrentWeekDates()
            }catch(e){
                console.log(e)
                this.errorMsg = 'Scan faild. Please contact developer.'
            }
            this.inSubmission = false
        },
        getDate12(){
            return dates.convertTimeTo12Hour(this.$props.employee.attendances[0].start)
        }
    },
    created() {
        this.getWeekData();
        // console.log(this.weekData);
        // console.log(this.todayLoginState())
    },
};
</script>
