<template>
    <div class="h-full flex items-center">
        <div class="w-full bg-white rounded-lg shadow-lg border p-6">
            <p class="mb-6 text-center text-lg font-bold">Sign In</p>
            <form @submit="signin">
                <div class="mb-6">
                    <input class="input" type="text" v-model="username" placeholder="Username or email">
                </div>
                <div class="relative mb-6">
                    <input class="input" :type="showPassword ? 'text' : 'password'" v-model="password" placeholder="Password">
                    <button type="button" class="p-2 absolute right-0 top-0" @click="showPassword = !showPassword">
                        <svg v-show="!showPassword" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline stroke-gray-500">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                        </svg>
                        <svg v-show="showPassword" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline stroke-gray-500">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                    </button>
                </div>
                <div class="mb-6">
                    <button class="btn block w-full bg-blue-500 text-white disabled:bg-blue-400" :disabled="inSubmission || !this.username || !this.password">
                        <span v-show="!inSubmission">
                            Sign in
                        </span>
                        <span class="text-center" v-show="inSubmission">
                            <svg class="animate-spin -ml-1 mr-1 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Please wait...
                        </span>
                    </button>
                </div>
                <p v-show="errorMsg" class="py-2 px-4 bg-red-500 text-white rounded-lg">{{errorMsg}}</p>
            </form>
        </div>
    </div>
</template>

<script>

import axios from 'axios'

export default {
    name: 'Login',
    props: ['baseUrl', 'onLogin'],
    data(){
        return{
            username: '',
            password: '',
            showPassword: false,
            inSubmission: false,
            errorMsg: null
        }
    },
    methods: {
        async signin(e){
            try{
                e.preventDefault()
                this.inSubmission = true
                this.errorMsg = null
                const pat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                this.username = this.username.toLowerCase()
                if(!this.username.match(pat)){
                    this.username += '@brandwind.in'
                }
                const response = await axios.post(this.$props.baseUrl + '/auth/login', {email: this.username, password: this.password})
                // console.log(response.data)
                localStorage.setItem('_token', response.data.login.token)
                await this.$props.onLogin()
            }catch(e){
                this.inSubmission = false
                this.errorMsg = 'Invalid username/password. Please try again'
            }
        }
    }
}
</script>