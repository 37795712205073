<template>
    <div class="fixed h-screen w-screen top-0 left-0 z-[996]">
        <div class="fixed h-screen w-screen z-[997] bg-black bg-opacity-60" @click="close"></div>
        <div class="fixed z-[998] w-screen shadow-lg bg-white rounded-t-2xl h-[80vh] left-0 transition-[bottom]" :class="{'-bottom-[80vh]': !loaded, 'bottom-0': loaded}">
            <div class="h-full w-full relative p-6">
                <button class="p-2 absolute top-4 right-4" @click="close">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                <video class="w-96 h-96 mt-12 mx-auto" ref="camera"></video> 
            </div>
            <!-- -->
        </div>
    </div>
</template>

<script>

import QrScanner from 'qr-scanner'
export default {
    nmae: 'Scan',
    props: ['onClose', 'onScan'],
    data(){
        return{
            loaded: false,
            scanner: null
        }
    },
    methods: {
        close(){
            this.loaded = false
            setTimeout(() => {
                 this.$props.onClose()
            }, 150)
           
        },
        async start(){
            // this.scanMode = true
            this.scanner = new QrScanner(
                this.$refs.camera,
                async (result) => {
                    this.scanner.stop();
                    await this.$props.onScan(result.data)
                    this.close()
                }, {},
            );
            this.scanner.start()
        },
    },
    mounted(){
        setTimeout(() => {
            this.loaded = true
            this.start()
        }, 100)
        
    }
}
</script>