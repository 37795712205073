<template>
    <div class="w-full text-white bg-gray-800 absolute top-0 left-0">
        <div class="container mx-auto flex justify-between items-center h-14 px-6">
            <img src="@/assets/images/logo.png" class="h-8" alt="">
            <div class="relative" v-if="employee.employee">
                <button @blur="menu = false" class="menu-btn">
                    <svg @click="menu = true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                    </svg>
                    <div v-show="menu" class="w-44 rounded-lg shadow-lg border bg-white absolute text-gray-800 top-10 right-0">
                        <span class="block w-full text-left py-2 px-4 cursor-pointer">Open Profile</span>
                        <span class="block w-full text-left py-2 px-4 cursor-pointer" @click="logout">Logout</span>
                    </div>
                </button>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Navbar',
    props: ['employee', 'onLogout'],
    data(){
        return{
            menu: false
        }
    },
    methods: {
        async logout(){
            localStorage.removeItem('_token')
            this.menu = false
            await this.$props.onLogout()
        }
    }
}
</script>

<style scoped>
.menu-btn{
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}
</style>