const dates = {
    getTotalMinutes(date, from = "0:0") {
        const [min, sec] = from.split(":");
        const startOfDay = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            min,
            sec,
            0
        ); // get the start of the day
        const elapsedMinutes = Math.floor((date - startOfDay) / (1000 * 60)); // get the elapsed minutes
        return elapsedMinutes;
    },
    formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    },
    calculateShiftProgress(loginTime) {
      const shiftLength = 8 * 60 * 60 * 1000; // 8 hours in milliseconds
      const now = new Date();
      const loginDate = new Date(now.toDateString() + ' ' + loginTime); // create a Date object from today's date and login time
      const diff = shiftLength - (now - loginDate); // calculate the difference between shift length and elapsed time
      if (diff < 0) return {hoursLeft: 0, minutesLeft: 0}; // if the shift has ended, return this message
      let hoursLeft = Math.floor(diff / (60 * 60 * 1000)); // calculate the remaining hours
      let minutesLeft = Math.ceil((diff % (60 * 60 * 1000)) / (60 * 1000)); // calculate the remaining minutes (rounded up)
      hoursLeft = parseInt(hoursLeft.toString().padStart(2, '0'))
      minutesLeft = parseInt(minutesLeft.toString().padStart(2, '0'))
      return {hoursLeft, minutesLeft}
    },
    monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ],
    weekDays: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thrusday",
        "Friday",
        "Saturday",
    ],
    getDateString(dateObj) {
        // Extract the day and month from the date object
        const day = dateObj.getDate();
        const monthIndex = dateObj.getMonth();

        // Get the month name from the array
        const monthName = this.monthNames[monthIndex];

        // Add the appropriate suffix to the day (e.g. "1st", "2nd", "3rd", etc.)
        const suffixes = ["th", "st", "nd", "rd"];
        const suffixIndex = day % 10 < 4 ? day % 10 : 0;
        const suffix = suffixes[suffixIndex];

        // Combine the day, suffix, and month name into a readable date string
        const dateString = `${day}${suffix} ${monthName}`;

        // Return the date string
        return dateString;
    },
    getTimeStringFromMS(milliseconds) {
        // Calculate the total number of minutes and hours from the given milliseconds
        const totalMinutes = Math.floor(milliseconds / 60000); // 1 minute = 60000 milliseconds
        const hours = Math.floor(totalMinutes / 60)
            .toString()
            .padStart(2, "0");
        const minutes = (totalMinutes % 60).toString().padStart(2, "0");

        // Combine the hours and minutes into a readable time string
        const timeString = `${hours}h ${minutes}m`;

        // Return the time string
        return timeString;
    },
    convertTimeTo12Hour(time24){
        const [hours, minutes] = time24.split(':');
        const amOrPm = hours >= 12 ? 'PM' : 'AM';
        const twelveHourFormat = hours % 12 === 0 ? 12 : hours % 12;
        const formattedHours = twelveHourFormat < 10 ? `0${twelveHourFormat}` : twelveHourFormat;
        // const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        return `${formattedHours}:${minutes} ${amOrPm}`;
      },
      clacTime(date1, date2){
        const milliseconds = date1 - date2
        let hours = Math.floor(milliseconds / 3600000); // 1 hour = 3600000 milliseconds
        let minutes = Math.floor((milliseconds % 3600000) / 60000); // 1 minute = 60000 milliseconds
        hours = hours.toString().padStart(2, '0'); // add leading zero if necessary
        minutes = minutes.toString().padStart(2, '0'); // add leading zero if necessary
        return `${hours}h ${minutes}m`;
    },
    calculateDuration(timeString) {
        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const [timeHours, timeMinutes] = timeString.split(':').map(Number);
        let hourDiff = hours - timeHours;
        let minDiff = minutes - timeMinutes;
        if (minDiff < 0) {
          hourDiff--;
          minDiff += 60;
        }
        if (hourDiff < 0) {
          hourDiff += 24;
        }
        const durationString = `${hourDiff.toString().padStart(2, '0')}h ${minDiff.toString().padStart(2, '0')}m`;
        return durationString;
      },
      compareDates(date1, date2) {
        // set the time to 0 for both dates
        date1.setHours(0, 0, 0, 0);
        date2.setHours(0, 0, 0, 0);
      
        const time1 = date1.getTime();
        const time2 = date2.getTime();
      
        if (time1 > time2) {
          return 1;
        } else if (time1 < time2) {
          return -1;
        } else {
          return 0;
        }
      },
      
};

export default dates;
