<template>
    <div class="h-screen w-screen bg-gray-200 relative py-14 text-gray-800">
        <div
            v-show="!ready"
            class="fixed z-[999] top-0 left-0 w-screen h-screen flex items-center justify-center bg-gray-800"
        >
            <preloader />
        </div>
        <navbar :employee="employee" :onLogout="getEmployee" />
        <div class="container mx-auto p-6 h-full">
            <login v-if="!token" :baseUrl="baseUrl" :onLogin="getEmployee" />
            <router-view v-else :employee="employee" :baseUrl="baseUrl" :onUpdate="getEmployee"  />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Navbar from "./components/Navbar.vue";
import Preloader from "./components/Preloader.vue";
import Login from './views/Login.vue';
export default {
    components: { Navbar, Preloader, Login },
    name: "App",
    data() {
        return {
            token: null,
            isLoaded: false,
            employee: {},
            ready: false,
            // baseUrl: "http://192.168.0.201:3000/api/v1",
            baseUrl: 'https://apiv1.brandwind.in/api/v1'
        };
    },
    methods: {
        async getEmployee() {
            try {
                const token = localStorage.getItem("_token");
                if (!token) {
                    throw new Error("Token not found.");
                }
                const headers = {
                    Authorization: `Bearer ${token}`,
                };
                const response = await axios.get(
                    this.baseUrl + "/employee/self",
                    { headers }
                );
                this.employee = response.data;
                // console.log(this.employee)
                // if(this.$route.name == 'login'){
                //   this.$router.push({name: 'dashboard'})
                // }
                this.token = token
            } catch (e) {
              // console.log(e)
                // localStorage.removeItem('_token')
                this.token = null
                // console.warn(this.$route.name);
                // if(this.$route.name !== 'login'){
                //   this.$router.push({name: 'login'})
                // }
            }
        },
    },
    async created() {
        await this.getEmployee();
        this.ready = true;
        // console.log(this.employee)
    },
};
</script>
